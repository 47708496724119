<template>
  <b-modal
    ref="payrate-setting-modal"
    hide-footer
    size="md"
    title="ตั้งค่าอัตราจ่าย"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      id="payrate-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group label="สถานะ" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-status"
          v-model="input.status"
          :options="statusOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="ชื่ออัตราจ่าย"
        label-for="input-payrate-title"
      >
        <b-form-input
          id="input-payrate-title"
          v-model="input.rateTitle"
          type="text"
          placeholder="ชื่ออัตราจ่าย"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="เรียงลำดับ"
        label-for="input-payrate-sort"
      >
        <b-form-input
          id="input-payrate-sort"
          v-model="input.sort"
          type="text"
          placeholder="เรียงลำดับ"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="อัตราจ่าย"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <table class="table-payrate table table-sm">
          <thead>
            <tr>
              <th width="60%">เลข</th>
              <th width="20%">จ่าย</th>
              <th width="20%">ส่วนลด(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bet in marketOpenBets" :key="bet.code">
              <td>
                <b-form-checkbox v-model="input.openBets[bet.code].isAvailable" :value="true">{{ bet.text }}</b-form-checkbox>
              </td>
              <td>
                <b-form-input size="sm" v-model="input.openBets[bet.code].pay" :disabled="!input.openBets[bet.code].isAvailable" type="number" :number="true"></b-form-input>
              </td>
              <td>
                <b-form-input size="sm" v-model="input.openBets[bet.code].discount" :disabled="!input.openBets[bet.code].isAvailable" type="number" :number="true"></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </b-form-group>
      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import {marketOpenBets} from '@/configs/market.config'
import {payRateInterface} from '@/interfaces/Market'
import MarketService from '@/services/marketService'
import Swal from 'sweetalert2'

export default {
  name: 'PayRateModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      needReload: false,
      isProcess: false,
      input: payRateInterface
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    statusOptions() {
      return [
        { text: 'ใช้งาน', value: 'Active' },
        { text: 'ไม่ใช้งาน', value: 'Inactive' }
      ]
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.input = JSON.parse(JSON.stringify(payRateInterface))
    },
    showModal() {
      this.$refs['payrate-setting-modal'].show()
    },
    hideModal() {
      this.$refs['payrate-setting-modal'].hide()
    },
    handleSubmit() {
      console.log('handleSubmit')
      console.log(JSON.stringify(this.input))
      if(this.input._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      MarketService.savePayRate(this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch(()=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    update() {
      MarketService.updatePayRate(this.input._id, this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch(()=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
.table-payrate {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
