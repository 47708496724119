import { render, staticRenderFns } from "./PayRateCard.vue?vue&type=template&id=70246c98&scoped=true&"
import script from "./PayRateCard.vue?vue&type=script&lang=js&"
export * from "./PayRateCard.vue?vue&type=script&lang=js&"
import style0 from "./PayRateCard.vue?vue&type=style&index=0&id=70246c98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70246c98",
  null
  
)

export default component.exports