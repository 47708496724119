<template>
  <div class="container">
    <div class="page-header d-flex justify-content-between align-items-center mb-1">
      <strong class="page-title">อัตราจ่าย</strong>
      <button class="add-payrate-btn btn btn-sm btn-link" @click="addNewRate">เพิ่มอัตราจ่าย</button>
    </div>

    <div class="row">
      <div class="col-md-4" v-for="rate in list">
        <PayRateCard :data="rate" @edit="editRate" />
      </div>
    </div>

    <PayRateModal :data="payrate" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import PayRateModal from './components/PayRateModal'
import {payRateInterface} from '@/interfaces/Market'
import MarketService from '@/services/marketService'
import PayRateCard from './components/PayRateCard'
import moment from '@/services/moment'

export default {
  name: 'PayRate',
  components: {
    PayRateModal,
    PayRateCard
  },
  data() {
    return {
      list: [],
      payrate: payRateInterface,
      isShowModal: false
    }
  },
  methods: {
    loadPayrates() {
      MarketService.getPayRates().then((response)=>{
        console.log('response', response)
        if(response.success) {
          this.list = response.data
        }
      })
    },
    addNewRate() {
      this.payrate = {
        ...payRateInterface,
        timestamp: moment().format('x')
      }
      this.isShowModal = true
    },
    editRate(rate) {
      this.payrate = {
        ...rate,
        timestamp: moment().format('x')
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        console.log('needReload!!!')
        this.loadPayrates()
        this.$store.dispatch('reloadMarketPayrates')
      }
    }
  },
  mounted() {
    this.loadPayrates()
  }
}
</script>
