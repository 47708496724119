<template>
  <div class="card">
    <div class="card-head d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="card-title my-3 ml-3 p-0">{{ title }}</h4>
        <span class="ml-2" :class="statusClass">({{ statusText }})</span>
      </div>
      <button class="btn btn-link btn-sm mr-2" @click="edit"><i class="fas fa-edit"></i></button>
    </div>
    <div class="card-body p-0">
      <table class="table table-sm mb-0">
        <thead>
          <tr>
            <th style="font-size: 90%; font-weight: normal;">เลข</th>
            <th width="15%" style="font-size: 90%; font-weight: normal;">จ่าย</th>
            <th width="15%" style="font-size: 90%; font-weight: normal;">ส่วนลด</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bet in marketOpenBets"
            :key="bet.code"
            v-if="betOptionAvailable(bet.code)"
            :class="{
              'text-success':betOptionAvailable(bet.code),
              'text-secondary':!betOptionAvailable(bet.code),
              'text-danger':betOption(bet.code, 'pay')===0
            }">
            <td>{{ bet.text }}</td>
            <td>{{ betOption(bet.code, 'pay') }}</td>
            <td>{{ betOption(bet.code, 'discount') }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { marketOpenBets } from '@/configs/market.config'

export default {
  name: 'PayRateCard',
  props: {
    data: {
      type: [Object, String],
      default: null
    }
  },
  computed: {
    title() {
      if(this.data){
        return this.data.rateTitle
      }else{
        return '-'
      }
    },
    statusText() {
      if(this.data){
        return this.data.status==='Active'?'ใช้งาน':'ไม่ใช้งาน'
      }else{
        return '-'
      }
    },
    marketOpenBets() {
      return marketOpenBets
    },
    statusClass() {
      if(this.data){
        switch(this.data.status) {
          case 'Active':
            return 'text-success'
          break

          case 'Cancel':
            return 'text-danger'
          break
        }
      }else{
        return '-'
      }
    }
  },
  methods: {
    edit() {
      this.$emit('edit', this.data)
    },
    betOption(code, param) {
      if(this.data) {
        return this.data.openBets[code][param]
      }else{
        return null
      }
    },
    betOptionAvailable(code) {
      if(this.data) {
        return this.data.openBets[code].isAvailable
      }else{
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// .card {
//   .card-head {
//     button {
//       display: none;
//     }
//   }
// }

// .card:hover {
//   .card-head {
//     button {
//       display: block;
//     }
//   }
// }
</style>
